<template>
  <div class="notfound">
    <!-- <headers></headers> -->
    <div class="center">
      <div class="center_text" v-if="ispc">
        <img src="@/assets/img/logo/404.png">
        <div class="right_text">
          <div class='contentdiv'>
            <p>This page could not be found</p>
            <div>
              <span>
                You can either stay and chill here, or go back to the beginning.
              </span>
            </div>
            <el-button @click="backs">  
              BACK TO HOME
            </el-button>
          </div>
        </div>
      </div>
      
      <div class="center_text center_text1" v-else>
        <img src="@/assets/img/logo/404.png">
        <div class="right_text right_text1">
          <div class='contentdiv'>
            <p>This page could not be found</p>
            <div>
              <span>
                You can either stay and chill here, or go back to the beginning.
              </span>
            </div>
            <el-button @click="backs">  
              BACK TO HOME
            </el-button>
          </div>
        </div>
      </div>
    </div>
    <footers></footers>
  </div>
</template>

<script>
import headers from "@/components/header_white.vue"
import footers from "@/components/footers.vue"
export default {
  name: "notfound",
  data() {
    return {
      ispc:true,
    };
  },
  components:{
    footers,
    headers
  },
  mounted(){
    let w = document.documentElement.clientWidth || document.body.clientWidth;
    if(w <= 900){
      this.ispc = false
    }else{
      this.ispc = true
    }
  },
  methods: {
    backs(){
      this.$router.push("/firstPage")
    },

  },
};
</script>


<style scoped lang="scss">
.notfound{
  width: 100%;
  height:100vh;
  .center{
    height:80%;
    width:100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .center_text{
      width:70%;
      height:460px;
      padding-top:60px;
      img{
        height:100%;
      }
      .right_text{
        width:65%;
        height:100%;
        float:right;
        display: flex;
        align-items: center;

      }
      div{
        font-family: 'Montserrat-light';
        font-style: normal;
        font-weight: 400;
        font-size: 23px;
        color: rgba(0, 0, 0, 0.87);
        span{
          display: inline-block;
          width:60%;
          line-height:30px;
        }
      }
      p{
        margin-top:25px;
        margin-bottom:25px;
        font-family: 'Montserrat-light';
        font-style: normal;
        font-weight: 900;
        font-size: 33px;
        color: rgba(0, 0, 0, 0.87);
      }
      .el-button{
        width:220px;
        height:43px;
        margin-top:30px;
        background:#0D8AF2;
        color:#fff;
        border-radius:8px;
        font-family: 'Montserrat-light';
        font-style: normal;
        font-weight: 600;
      }
      .el-button:hover{
        background: #0255D2;
      }
    }
    .center_text1{
      width:70%;
      height:1560px;
      text-align: center;
      img{
        height:30%;
      }
      .right_text1{
        width:100%;
        height:auto;
        text-align: center;
        .contentdiv{
          width:100%;
          border:0;
          padding-left:0;
        }
      }
      p{
        margin-top:45px;
        margin-bottom:45px;
        font-family: 'Montserrat-light';
        font-style: normal;
        font-weight: 900;
        font-size: 65px;
        color: rgba(0, 0, 0, 0.87);
      }
      div{
        font-family: 'Montserrat-light';
        font-style: normal;
        font-weight: 400;
        font-size: 55px;
        color: rgba(0, 0, 0, 0.87);
        span{
          display: inline-block;
          width:60%;
          line-height:55px;
        }
      }
      .el-button{
        width:400px;
        height:93px;
        margin-top:80px;
        background:#0D8AF2;
        color:#fff;
        border-radius:8px;
        font-family: 'Montserrat-light';
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
      }
    }
  }
}

.contentdiv{
  border-left:2px solid rgba(0, 0, 0, 0.12);
  padding-left:100px;
}


</style>